<template>
  <footer>

    <div class="mt-10 sm:mt-16 px-psec max-w-[1800px] mx-auto py-10 md:py-16 text-base grid grid-cols-1 gap-x-8 md:grid-cols-12 lg:gap-x-16">
      <div class="col-span-1 md:col-span-4">
        <div class="cos-ftr-nav-head">Beliebte Kreuzfahrt-Reedereien</div>
        <ul class="cos-ftr-nav cos-ftr-nav-col cos-ftr-nav-pro-flex">
          <li>
            <NuxtLink href="/kreuzfahrt-reederei/aida-kreuzfahrten" aria-label="Kreuzfahrten mit AIDA">AIDA</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/kreuzfahrt-reederei/carnival-kreuzfahrten" aria-label="Carnival Cruise Line">Carnival</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/kreuzfahrt-reederei/costa-kreuzfahrten" aria-label="Kreuzfahrten mit Costa">Costa</NuxtLink>
          </li>
          <li>
            <a href="https://kreuzfahrten.costeri.de/de/suche?sort=pauf&sdt=5&bdt=720&red=9" rel="noopener noreferrer" aria-label="Kreuzfahrten mit Explora Journeys">Explora</a>
          </li>
          <li>
            <NuxtLink href="/kreuzfahrten/hurtigruten-expeditions" aria-label="Kreuzfahrten mit HX (Hurtigruten Expeditions)">Hurtigruten</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/kreuzfahrt-reederei/msc-kreuzfahrten" aria-label="Kreuzfahrten mit MSC Cruises">MSC</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/kreuzfahrt-reederei/ncl-kreuzfahrten" aria-label="Kreuzfahrten mit Norwegian Cruise Line">NCL</NuxtLink>
          </li>
        </ul>

        <div class="cos-ftr-nav-head">Top Luxusreisen</div>
        <ul class="cos-ftr-nav cos-ftr-nav-col cos-ftr-nav-pro-flex">
          <li>
            <NuxtLink href="/urlaub/vereinigte-arabische-emirate/dubai" aria-label="Dubai">Dubai</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/urlaub/asien/malediven" aria-label="Malediven">Malediven</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/urlaub/afrika/mauritius" aria-label="Mauritius">Mauritius</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/urlaub/afrika/seychellen" aria-label="Seychellen">Seychellen</NuxtLink>
          </li>
          <li>
            <NuxtLink href="/luxuskreuzfahrten/explora-journeys" aria-label="'Ocean' Journeys">'Ocean' Journeys</NuxtLink>
          </li>
        </ul>

        <ul class="mb-14 flex items-center">
          <li><img src="/images/partner/cc_amex.png" class="w-[64px] h-[64px]" width="80" height="80" alt="Amex Logo" aria-label="Amex Logo" loading="lazy"></li>
          <li><img src="/images/partner/cc_mc.png" class="w-[64px] h-[45px]" width="220" height="156" alt="Mastercard Logo" aria-label="Mastercard Logo" loading="lazy"></li>
          <li><img src="/images/partner/cc_visa.png" class="w-[64px] h-[20px]" width="1000" height="324" alt="Visa Logo" aria-label="Visa Logo" loading="lazy"></li>
        </ul>
      </div>

      <div class="col-span-1 md:col-span-8">
        <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 lg:gap-x-16">

          <div class="cos-ftr-nav-col">
            <div class="cos-ftr-nav-head">Top Fernreisen</div>
            <ul class="cos-ftr-nav cos-ftr-nav-flex">
              <li>
                <NuxtLink href="/urlaub/australien" aria-label="Australien">Australien</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/vereinigte-arabische-emirate/dubai" aria-label="Dubai">Dubai</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/afrika/kapverden" aria-label="Kapverden">Kapverden</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/mexiko" aria-label="Mexiko">Mexiko</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/asien/thailand" aria-label="Thailand">Thailand</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/usa" aria-label="USA">USA</NuxtLink>
              </li>
            </ul>
          </div>

          <div class="cos-ftr-nav-col">
            <div class="cos-ftr-nav-head">Beliebte Reiseziele</div>
            <ul class="cos-ftr-nav cos-ftr-nav-flex">
              <li>
                <NuxtLink href="/urlaub/afrika/aegypten" aria-label="Ägypten">Ägypten</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/spanien/balearen" aria-label="Balearen">Balearen</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/griechenland" aria-label="Griechenland">Griechenland</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/spanien/balearen/ibiza" aria-label="Ibiza">Ibiza</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/italien" aria-label="Italien">Italien</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/spanien/kanaren" aria-label="Kanaren">Kanaren</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/kroatien" aria-label="Kroatien">Kroatien</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/spanien/balearen/mallorca" aria-label="Mallorca">Mallorca</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/afrika/marokko" aria-label="Marokko">Marokko</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/portugal" aria-label="Portugal">Portugal</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/afrika/tunesien" aria-label="Tunesien">Tunesien</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/tuerkei" aria-label="Türkei">Türkei</NuxtLink>
              </li>
              <li>
                <NuxtLink href="/urlaub/zypern" aria-label="Zypern">Zypern</NuxtLink>
              </li>
            </ul>
          </div>

          <div class="cos-ftr-nav-col">
            <div class="cos-ftr-nav-head">Inspiration</div>
            <ul class="cos-ftr-nav cos-ftr-nav-static">
              <li class="flex items-center">
                <CWidgetSvg name="ship" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/kreuzfahrten/hurtigruten-expeditions" aria-label="Hunting the Light">Hunting the Light</NuxtLink>
              </li>

              <li class="flex items-center">
                <CWidgetSvg name="ship" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/luxuskreuzfahrten/explora-journeys" aria-label="'Ocean' Journeys">'Ocean' Journeys</NuxtLink>
              </li>

              <li class="flex items-center">
                <CWidgetSvg name="people_roof" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/pauschalreisen/cluburlaub/robinson-premium-cluburlaub" aria-label="ROBINSON Club">ROBINSON Club</NuxtLink>
              </li>
            </ul>
          </div>

          <div class="xl:mt-4 lg:mb-10 order-3">
            <div class="flex flex-wrap items-center">
              <NuxtLink href="/" class="border-none bg-none hover:bg-transparent" aria-label="Logo">
                <img src="~/assets/images/logo.svg" class="logo w-[72px] h-[38px]" width="72" height="38" alt="Logo Reisebüro Costéri" aria-label="Logo" loading="lazy">
              </NuxtLink>
            </div>
            <p class="mt-4 text-stone-500 text-sm"><strong>{{ company.name }}</strong> vermittelt Reiseprodukte und ist ein unabhängiges <strong>Reisebüro</strong> mit individueller Beratung und umfangreichem Service gemacht für großartige <strong>Urlaubsreisen</strong>.</p>
            <p class="mt-2 text-stone-500 text-sm italic">{{ company.name }} betreut mit vielfältigen Reiseservices die DACH-Region mit Schwerpunkt im deutschen Markt.</p>
            <div class="mt-6 flex flex-wrap items-center text-stone-600">
              <div class="text-glam-500 mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" focusable="false" viewBox="0 0 448 512">
                  <path d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96C448 60.65 419.3 32 384 32zM351.6 321.5l-11.62 50.39c-1.633 7.125-7.9 12.11-15.24 12.11c-126.1 0-228.7-102.6-228.7-228.8c0-7.328 4.984-13.59 12.11-15.22l50.38-11.63c7.344-1.703 14.88 2.109 17.93 9.062l23.27 54.28c2.719 6.391 .8828 13.83-4.492 18.22L168.3 232c16.99 34.61 45.14 62.75 79.77 79.75l22.02-26.91c4.344-5.391 11.85-7.25 18.24-4.484l54.24 23.25C349.5 306.6 353.3 314.2 351.6 321.5z" />
                </svg>
              </div>
              <div class="font-bold text-xl">{{ company.phoneNumber }}</div>
            </div>
            <div class="text-xs sm:text-sm pt-2 sm:pt-1 text-black">{{ company.openingHours }}</div>

            <div class="pt-6 pb-3 flex flex-wrap items-center text-xs leading-4">
              <div>
                <a href="https://www.facebook.com/continentventures" rel="noopener noreferrer" class="flex flex-wrap items-center text-[#6e6e6e] hover:text-glam-500 transition duration-500 hover:duration-300 ease-in hover:ease-in" title="Facebook" aria-label="Facebookseite" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" focusable="false" viewBox="0 0 512 512">
                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
                  </svg>
                  <div class="ml-1">continentventures</div>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/reisebuero_costeri" rel="noopener noreferrer" class="ml-2 flex flex-wrap items-center text-[#6e6e6e] hover:text-glam-500 transition duration-500 hover:duration-300 ease-in hover:ease-in" title="Instagram" aria-label="Instagramseite" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true" focusable="false" viewBox="0 0 3333 3333" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd">
                    <path d="M1667 0c920 0 1667 746 1667 1667 0 920-746 1667-1667 1667C747 3334 0 2588 0 1667 0 747 746 0 1667 0zm-390 752h780c293 0 532 237 532 525v778c0 289-239 525-532 525h-780c-293 0-532-236-532-525v-778c0-289 240-525 532-525zm385 421c285 0 516 231 516 516s-231 516-516 516-516-231-516-516 231-516 516-516zm0 174c188 0 341 153 341 341s-153 341-341 341c-189 0-341-153-341-341s153-341 341-341zm499-246c46 0 84 37 84 84 0 46-37 84-84 84-46 0-84-37-84-84 0-46 37-84 84-84zm-820-200h652c245 0 445 199 445 443v656c0 244-200 443-445 443h-652c-245 0-445-199-445-443v-656c0-244 200-443 445-443z" />
                  </svg>
                  <div class="ml-1">reisebuero_costeri</div>
                </a>
              </div>
            </div>

            <p class="text-sm">
              Folge uns auf Facebook und Instagram, um immer über die neuesten Angebote und Reiseziele informiert zu sein.
            </p>
          </div>

          <div class="cos-ftr-nav-col order-2 lg:order-3">
            <div class="cos-ftr-nav-head">Kontakt & Service</div>
            <div class="cos-ftr-nav cos-ftr-nav-static mb-8 text-base">

              <ul>
                <li class="flex items-center">
                  <CWidgetSvg name="globe" class="mr-2 w-4 h-4 text-glam-500" />
                  <NuxtLink href="/ueberuns" aria-label="Wir über uns">Wir über uns</NuxtLink>
                </li>

                <li class="flex items-center">
                  <CWidgetSvg name="person_luggage" class="mr-2 w-4 h-4 text-glam-500" />
                  <NuxtLink href="/service/reiseberatung" aria-label="Reiseberatung">Reiseberatung</NuxtLink>
                </li>

                <li class="flex items-center">
                  <CWidgetSvg name="book_reader" class="mr-2 w-4 h-4 text-glam-500" />
                  <NuxtLink href="/ratgeber" aria-label="Ratgeber">Ratgeber</NuxtLink>
                </li>

                <li class="flex items-center">
                  <CWidgetSvg name="newsletter" class="mr-2 w-4 h-4 text-glam-500" />
                  <NuxtLink href="/newsletter/anmeldung" aria-label="Newsletter">Newsletter</NuxtLink>
                </li>

                <li class="flex items-center">
                  <div class="w-5 h-4 flex justify-center">
                    <CWidgetSvg name="info" class="text-glam-500 w-[6px] h-4" />
                  </div>
                  <NuxtLink class="ml-1" href="/service/haeufige-fragen" aria-label="Häufige Fragen">Häufige Fragen (FAQ)</NuxtLink>
                </li>
              </ul>

              <!-- <div class="flex flex-wrap mt-5 opacity-80">
                <img src="~/assets/images/partner/partner_von_schmetterling.svg" class="h-[48px] w-[140px]" width="140" height="48" alt="Reisebürokooperation Schmetterling" aria-label="Logo Schmetterling" loading="lazy" />
              </div> -->

              <div class="mt-4 cos-google-review text-sm">
                Wir freuen uns auf dein Feedback. Veröffentliche doch eine Rezension in unserem
                <a href="https://g.page/r/CQXtjvqXDUPBEBI/review" rel="noopener noreferrer" target="_blank" aria-label="Google-Profil">Google-Profil</a>
              </div>
            </div>
          </div>

          <div class="cos-ftr-nav-col order-1 xl:order-3">
            <div class="cos-ftr-nav-head">Dein Urlaub</div>
            <ul class="cos-ftr-nav cos-ftr-nav-static">
              <li class="flex items-center">
                <CWidgetSvg name="ship" class="mr-2 w-4 h-3 text-glam-500" />
                <NuxtLink href="/kreuzfahrten" aria-label="Kreuzfahrten">Kreuzfahrten</NuxtLink>
              </li>
              <li class="flex items-center">
                <CWidgetSvg name="umbrella_beach" class="mr-2 w-4 h-3 text-glam-500" />
                <NuxtLink href="/pauschalreisen/suche" aria-label="Pauschalreisen">Pauschalreisen</NuxtLink>
              </li>
              <li class="flex items-center">
                <CWidgetSvg name="people_roof" class="mr-2 w-4 h-3 text-glam-500" />
                <NuxtLink href="/pauschalreisen/cluburlaub/suche" aria-label="Cluburlaub">Cluburlaub</NuxtLink>
              </li>
              <!-- <li><NuxtLink href="/pauschalreisen/familienurlaub/suche" target="_blank" aria-label="Familienurlaub">Familienurlaub</NuxtLink></li> -->
              <!-- <li><NuxtLink :href="'https://www.fitreisen.de/wellnessurlaub/?agenturnr=10009015&currency=eur'" target="_blank" aria-label="Wellnessreisen">Wellnessreisen</NuxtLink></li> -->
              <!-- <li><NuxtLink :href="'https://www.fitreisen.de/kuren/kurreisen/?agenturnr=10009015&currency=eur'" target="_blank" aria-label="Kurreisen">Kurreisen</NuxtLink></li> -->
              <li class="flex items-center">
                <CWidgetSvg name="car" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/service/mietwagen-sunny-cars-angebote" aria-label="Mietwagen">Mietwagen</NuxtLink>
              </li>
              <li class="flex items-center">
                <CWidgetSvg name="sub_train" class="mr-2 w-4 h-4 text-glam-500" />
                <a :href="'https://www.ameropa.de/reiseangebote/client/720485/rundreisen'" rel="noopener noreferrer" target="_blank" aria-label="Rund- und Städtereisen">Rund- und Städtereisen</a>
              </li>
              <!-- <li class="flex items-center">
                <CWidgetSvg name="kitchen_set" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/ferienwohnungen/suche" aria-label="Ferienwohnungen">Ferienwohnungen</NuxtLink>
              </li> -->
              <li class="flex items-center">
                <CWidgetSvg name="bed" class="mr-2 w-4 h-3.5 text-glam-500" />
                <NuxtLink href="/hotels/hotels-zum-verlieben" aria-label="Hotels">Hotels</NuxtLink>
              </li>
              <li class="flex items-center">
                <CWidgetSvg name="parachute_box" class="mr-2 w-4 h-4 text-glam-500" />
                <NuxtLink href="/service/reiseversicherungen/reiseschutz-von-ergo" aria-label="Reiseversicherungen">Reiseversicherungen</NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-[#356799] h-1"></div> -->
    <!-- <div class="bg-glam-400 h-[1px]"></div> -->

    <div class="px-4 pt-3 pb-10 bg-stone-50">
      <div class="mt-8 mb-1 flex justify-center items-center">
        <a href="https://www.provenexpert.com/costeri-travel-tours-gmbh/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget" title="Kundenbewertungen &amp; Erfahrungen zu Costéri Travel-Tours GmbH. Mehr Infos anzeigen." target="_blank" rel="noopener noreferrer">
          <img height="109" width="150" src="https://static.costeri.de/media/images/proven_widget.png" loading="lazy" alt="Kundenbewertungen und Erfahrungen zu Costéri Travel-Tours GmbH. Mehr Infos anzeigen.">
          <!-- <img height="109" width="150" src="~/assets/images/proven_widget.png" loading="lazy" alt="Kundenbewertungen und Erfahrungen zu Costéri Travel-Tours GmbH. Mehr Infos anzeigen."> -->
        </a>
      </div>

      <!-- <div class="flex flex-wrap justify-center items-center uppercase tracking-wider text-center">
        Reiseangebote mit
        <CWidgetSvg name="heart" class=" mx-1 mb-[2px] sm:mb-1 w-4 h-4 text-lux-300" />aus der Metropolregion
      </div> -->

      <ul class="py-3 cos-ftr-legal flex flex-wrap justify-center items-center text-xs uppercase">
        <li>
          <NuxtLink href="/impressum">Impressum</NuxtLink>
        </li>
        <li>
          <NuxtLink href="/nutzungsbedingungen">Nutzungsbedingungen</NuxtLink>
        </li>
        <li>
          <NuxtLink href="/datenschutz">Datenschutz</NuxtLink>
        </li>
        <li>
          <NuxtLink href="/haftungsausschluss">Haftungsausschluss</NuxtLink>
        </li>
        <li>
          <a href="javascript: Cookiebot.renew()" aria-label="Cookies verwalten">Cookies verwalten</a>
        </li>
        <li>
          <NuxtLink href="/agb">AGB</NuxtLink>
        </li>
      </ul>

      <div class="container flex justify-center lg:justify-end items-center text-xs uppercase">&copy; Reisebüro {{ company.nameOfficial }} {{ new Date().getFullYear() }}</div>

    </div>

  </footer>
</template>

<script setup lang="ts">
const { company } = useAppConfig()
</script>
